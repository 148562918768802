<template>
    <section class="bg-light">
      <div class="container">
        <h2 class="pb-2">{{ titulo || title || '' }}</h2>
        <div class="row row-cols-1 g-4" v-bind:class="[columnas > 0 ? 'row-cols-md-' + columnas : 'row-cols-md-3']">
                <div class="col" v-for="( el, i ) in items" :key="i">
            <a class="d-block pe-lg-4 lh-sm" :href="el.url">
              <h3 class="mb-0 fs-5">{{el.title}}</h3>
              <small class="text-muted">
                <span v-if="el.url && el.url.includes('tel:')" class="material-symbols-outlined gld-span">call</span>    
                <span v-if="el.url && el.url.includes('mailto:')" class="material-symbols-outlined fac-span">mail</span>
                {{el.subtitle}}
              </small>
            </a>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  const axios = require("axios");
  export default {
    name: "GridLinkComponent",
    props: {
      titulo: String,
      filtros: Object,
      data: Object,
      columnas: String,
    },
    data() {
      return {
        hash: "GLC",
        items: null,
        title: null,
        desc: null,
      };
    },
    
    mounted() {
        if(this.data == null){
            let params = {};
            if(this.filtros != null && this.filtros != ""){
                params = this.filtros;
            }
            params.tipo = this.hash;
            axios.post(process.env.VUE_APP_API_URL,params)
            .then(response => {
                this.items = response.data ? response.data.items : null
                this.title = response.data ? response.data.title : null
                this.desc = response.data ? response.data.desc : null
                localStorage.setItem(this.hash, JSON.stringify(response));
            })
            .catch (error => {
                if (!error.response) {
                    console.log("Fuera de Linea");
                    if(localStorage.getItem(this.hash)){
                        console.log("Cargando elementos de cache para " + this.hash);
                        var resp = JSON.parse(localStorage.getItem(this.hash));
                        this.items = resp.data ? resp.data.items : null                    
                        this.title = resp.data ? resp.data.title : null
                        this.desc = resp.data ? resp.data.desc : null
                    }
                }
            })
        }else{
            this.items = this.data;
        }
    },
};
</script>

