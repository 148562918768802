<template>
  <div class="" style="min-height: 150px">
    <div
      style="text-align: justify; overflow-wrap: break-word"
      v-if="
        !customView &&
        !isExpedientesComponent &&
        !isFormComponent &&
        isDocumentListTesoreria
      "
    >
      <div v-html="html"></div>
    </div>
    <div class="dinamic" v-if="customView">
      <component v-bind:is="customView"></component>
    </div>
    <DocumentListComponent
      v-if="isDocumentListTesoreria"
      :show-fecha="false"
      :v-show-more="false"
      titulo="Tesorería General de la Provincia"
      :data="[
        {
          title: '01-ACTUALIZACIÓN DE RESPONSABLE',
          url: 'https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/pdf_hacienda/01-CUENTAS%20BANCARIAS%20OFICIALES_v1.2.pdf',
          target: 'blank',
        },
        {
          title: '02-APERTURA CUENTA BANCARIA OFICIAL',
          url: 'https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/pdf_hacienda/02-APERTURA%20CUENTA%20BANCARIA%20OFICIAL%20(3).pdf',
          target: 'blank',
        },
        {
          title: '03-MODIFICACIÓN DE DATOS DE CUENTA BANCARIAS',
          url: 'https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/pdf_hacienda/03-MODIFICACI%C3%93N%20DE%20DATOS%20DE%20CUENTA%20BANCARIAS%20(2).pdf',
          target: 'blank',
        },
        {
          title: '04-CIERRE CUENTAS BANCARIAS',
          url: 'https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/pdf_hacienda/04-CIERRE%20CUENTAS%20BANCARIAS%20-.pdf',
          target: 'blank',
        },
      ]"
    />
    <div class="container" v-if="isDocumentListTesoreria">
      <ol style="font-size: 20px">
        <li>
          Seleccionar el formulario del trámite que desea realizar, descargar.
        </li>
        <li>
          Completar los campos correspondientes en la computadora, imprimir por
          triplicado y firmar por las personas correspondientes
        </li>
        <li>
          Presentar los formularios con la documentación requerida, en la
          dirección que figura al pie de página de los mismos
        </li>
      </ol>
    </div>
    <DocumentListComponent
      v-if="isDocumentListComponent"
      titulo="Resultados - Exámen de Ingreso y Promociones 2023"
      :data="[
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/ing_ext_31-10_y7-11_2023.pdf',
          fecha: ' 31/10 y 07/11/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'https://sanjuan.gob.ar/docs/resultadosexamenes/IngreExtr_10_17_DE_OCTUBRE.pdf',
          fecha: ' 10 y 17/10/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'https://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_19-26_09y03_10.pdf',
          fecha: ' 19 y 26/09 y 03/10/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'https://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_05_12_09_2023.pdf',
          fecha: ' 5 y 12/09/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_22_29_08-2023.pdf',
          fecha: '22 y 29/08/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_promociones_15_8_2023.pdf',
          fecha: '15/08/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A ',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_25_07_01_08_2023.pdf',
          fecha: '25/07 y 01/08/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_18_07_2023.pdf',
          fecha: '18/07/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A ',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_11_12_13_07_2023.pdf',
          fecha: '11/07, 12/07 y 13/07/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_4_6_07_2023.pdf',
          fecha: '04/07 y 06/07/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A ',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_27_29_06_2023.pdf',
          fecha: '27/06 y 29/06/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_21_23_06_2023.pdf',
          fecha: '21/06 y 23/06/23',
        },
        {
          title: 'Resultados Jachal - Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_jachal_15_06_2023.pdf',
          fecha: '15/06 y 16/06/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_15_16_06_2023.pdf',
          fecha: '15/06 y 16/06/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_30_5_01_06_2023.pdf',
          fecha: '30/05 y 01/06/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_06_y_08_06_2023.pdf',
          fecha: '06 y 08/06/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_23_24_05_2023.pdf',
          fecha: '23 y 24/05/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_16_05_2023.pdf',
          fecha: '16/05/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_09_05_2023.pdf',
          fecha: '09/05/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_extr_02_05_2023_Recuperatorio.pdf',
          fecha: '03/05/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_extr_02_05_2023pdf',
          fecha: '02/05/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_25_04_2023.pdf',
          fecha: '25/04/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_18_04_2023.pdf',
          fecha: '18/04/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_11-04-2023.pdf',
          fecha: '11/04/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/ing_ext_04_04_2023.pdf',
          fecha: '04/04/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/Examen_28_marzo.pdf',
          fecha: '28/03/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/IngreExtr_21_03_2023.pdf',
          fecha: '21/03/23',
        },
        {
          title: 'Resultados Ingreso Extraordinarios Ley 2440 A',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/IngreExtr_07_14_03_2023.pdf',
          fecha: '07/03/23 y 14/03/23',
        },
      ]"
    />
    <DocumentListComponent
      v-if="isDocumentListComponent"
      :show-fecha="false"
      titulo="Resultados - Exámenes de Ingreso y Promociones 2022"
      :data="[
        {
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/08-11-22_11-11-22.pdf',
          title: 'Exámenes 11/11/22 - 08/11/22',
        },
        {
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/25-10-22_28-10-22.pdf',
          title: 'Exámenes 28/10/22 - 25/10/22',
        },
        {
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/12-10-22_14-10-22.pdf',
          title: 'Exámenes 14/10/22 - 12/10/22',
        },
        {
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/03-10-22_06-10-22.pdf',
          title: 'Exámenes 06/10/22 - 03/10/22',
        },
        {
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/19-09-22_22-09-22.pdf',
          title: 'Exámenes 22/09/22 - 19/09/22',
        },
        {
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/30-08-22_07-09-22.pdf',
          title: 'Exámenes 07/09/22 - 30/08/22',
        },
        {
          title: 'Exámenes 23/08/22 - 19/08/22',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/19-08-22_23-08-22.pdf',
        },
        {
          title: 'Exámenes 09/08/22',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/09-08-22.pdf',
        },
        {
          title: 'Exámenes 05/08/22 - 02/08/22',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/02-08-22_05-08-22.pdf',
        },
        {
          title: 'Exámenes 22/07/22 - 19/07/22',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/19-07-22_22-07-22.pdf',
        },
        {
          title: 'Exámenes 08/07/22 - 05/07/22',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/05-07-22_08-07-22.pdf',
        },
        {
          title: 'Exámenes 28/06/22 - 23/06/22',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/23-06-22_28-06-22.pdf',
        },
        {
          title: 'Exámenes 21/06/22 - 14/06/22',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/14-06-22_21-06-22.pdf',
        },
        {
          title: 'Exámenes 10/06/22 - 07/06/22',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/07-06-22_10-06-22.pdf',
        },
        {
          title: 'Exámenes 03/06/22',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/03-06-22.pdf',
        },
        {
          title: 'Exámenes 26/04/22',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/26-04-22.pdf',
        },
        {
          title: 'Exámenes 05/04/22',
          url: 'http://sanjuan.gob.ar/docs/resultadosexamenes/05-04-22.pdf',
        },
      ]"
    />
    <FormComponent v-if="isFormComponent" />
    <ExpedientesComponent
      v-if="isExpedientesComponent"
      :filtros="{ site: 'dlc.siged.consulta.exp' }"
    />

    <div class="text-center pt-4">
      <p>
        <button v-on:click="back" class="btn btn-secondary text-uppercase">
          Volver
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ExpedientesComponent from "./components/resources/ExpedientesComponent.vue";
import FormComponent from "./components/resources/FormComponent.vue";
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
export default {
  name: "InfoView",
  props: {
    uid: String,
    filtros: Object,
  },
  data() {
    return {
      html: null,
      customView: false,
      isExpedientesComponent: false,
      isFormComponent: false,
      isDocumentListComponent: false,
      isDocumentListTesoreria: false,
    };
  },
  methods: {
    back() {
      history.back();
    },
  },
  mounted() {
    if (this.uid === "CUMV-VIEW-DocumentListComponent") {
      this.isDocumentListComponent = true;
    } else if (this.uid === "CUMV-VIEW-FormComponent") {
      this.isFormComponent = true;
    } else if (this.uid === "CUMV-VIEW-TesoreriaGeneralDeLaProvincia") {
      this.isDocumentListTesoreria = true;
    } else if (this.uid === "CUMV-VIEW-ExpedientesComponent") {
      this.isExpedientesComponent = true;
    } else if (this.uid.includes("CUMV-"))
      switch (this.uid) {
        case "": {
          this.html = ` `;
          break;
        }
      }
    else {
      let params = {};
      if (this.filtros != null && this.filtros != "") {
        params = this.filtros;
      }
      params.uid = this.uid;

      axios
        .post(process.env.VUE_APP_API_URL, params)
        .then((response) => {
          this.html = response.data ? response.data.html : null;
          localStorage.setItem(this.uid, JSON.stringify(response));
        })
        .catch((error) => {
          if (!error.response) {
            console.log("Fuera de Linea");
            if (localStorage.getItem(this.uid)) {
              console.log("Cargando elementos de cache para " + this.uid);
              var resp = JSON.parse(localStorage.getItem(this.uid));
              this.html = resp.data ? resp.data.html : null;
            }
          }
        });
    }
  },
  components: { ExpedientesComponent, FormComponent, DocumentListComponent },
};
</script>
