<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
import ExpedientesComponent from "./components/resources/ExpedientesComponent.vue";
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
    /* eslint-disable */
    ExpedientesComponent,
  },
  data() {
    return {
      site: 1,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
  methods: {
    trLCGA() {
      gtag('event', 'link_rentas_hacienda', {
        'event_category': 'Navigation',
        'event_label': 'Hacienda a Rentas'
      });
    }
  },
};
</script>


<!-- BOTON LICITACIÓN -->
<template>

  <!-- Moratoria -->
  <MultiSliderComponent titulo="" :elementos="1" :mostrar-titulo="false" :mostrar-bordes="true" :ajustar="true" :data="[
    {
      desc: null,
      imagen: require('@/assets/img/banner digital_mas benef_osse-43.jpg'),
      url: 'https://facturaweb.osse.com.ar/',
      target: 'blank_',
    },
  ]" />
  <!-- Moratoria -->

  <section class="container mb-0 pb-0">
    <div class="row">
      <div class="col-lg-12">
        <div class="alert alert-warning fade show p-4">
          <div class="row">
            <div class="col-lg-6">
              <strong class="text-ptn-color-gsj-2">¡Atención!</strong>
              Reanudación de Procedimiento de Licitación Pública
            </div>
            <div class="col-lg-6">
              <a href="http://sanjuan.gov.ar/docs/hacienda/compras/Resolución 1592-SFH-2020.pdf"
                class="btn btn-secondary text-bg-ptn-color-gsj-brand" style="width: 100%" target="_blank">Descargar
                resolución</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <ITCGridComponent :columnas="4" :data="[
    {
      icon: 'ptn-i-documento',
      target: null,
      title: 'Consulta de Expedientes',
      url: '?uid=CUMV-VIEW-ExpedientesComponent',
    },
    {
      icon: 'question_answer',
      target: null,
      title: 'Consultas y Solicitudes',
      url: '?uid=CUMV-VIEW-FormComponent',
    },
    {
      icon: 'ptn-i-acuerdo',
      target: null,
      title: 'Contrataciones',
      url: 'https://compraspublicas.sanjuan.gob.ar/',
    },
    {
      icon: 'ptn-i-ciudadano-digital',
      target: null,
      title: 'Ciudadano Digital',
      url: 'https://mi.sanjuan.gob.ar/',
    },
  ]" />

  <!--  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <a
          href="https://licitaciones.sanjuan.gob.ar/"
          class="btn btn-secondary text-bg-ptn-color-gsj-brand"
          style="width: 100%"
          target="_blank"
          >Accede al portal de procesos de contrataciones públicas desde el
          05/10/2020 10:00 hs</a
        >
      </div>
    </div>
  </div> -->

  <section class="container mb-0">
    <div class="row">
      <div class="col-lg-12">
        <div class="alert alert-warning fade show p-4">
          <div class="row">
            <div class="col-lg-6">
              <strong class="text-ptn-color-gsj-2">¡Atención!</strong> Turnos
              RUPE
            </div>
            <div class="col-lg-6">
              <a href="https://turnos.sanjuan.gob.ar" class="btn btn-secondary text-bg-ptn-color-gsj-brand"
                style="width: 100%" target="_blank">Acceder</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <DocumentListComponent
    resultados="5"
    :show-fecha="false"
    titulo="Guía de Trámites"
    :filtros="{ site: 'dlc.tramites.hacienda' }"
  /> -->

  <NewsComponent titulo="Noticias" />

  <GridLinkComponent titulo="Links Útiles" :data="[
    {
      title: 'Digesto Administrativo',
      subtitle: 'Página Web',
      url: 'https://digesto.sanjuan.gob.ar/digesto/',
    },
    {
      title: 'Régimen Federal de Responsabilidad Fiscal',
      subtitle: 'Página Web',
      url: 'https://web.sanjuan.gob.ar/hacienda/',
    },
    {
      title: 'Consulta Comprobante de Pago y Retenciones',
      subtitle: 'Página Web',
      url: 'https://consultapagosyretenciones.sanjuan.gob.ar/',
    },
    {
      title: 'Recaudación Impositiva Provincial',
      subtitle: 'Página Web',
      url: 'http://recaudacionrentas.sanjuan.gob.ar/',
    },
    {
      title: 'Plan Estratégico San Juan',
      subtitle: 'Página Web',
      url: 'https://planestrategico.sanjuan.gob.ar/',
    },
    {
      title: 'Instituto de Investigaciones Económicas y Estadísticas',
      subtitle: 'Página Web',
      url: 'https://web.sanjuan.gob.ar/iiee/',
    },
  ]" />

  <CardGridComponent titulo="Organismos" columnas="3" :data="[
    {
      target: '_blank',
      title: 'Dirección General Rentas',
      url: 'https://rentas.dgrsj.gob.ar/',
    },
    {
      target: '_blank',
      title: 'Contaduría General de la Provincia',
      url: 'https://cgp.sanjuan.gob.ar/',
    },
    {
      target: '_blank',
      title: 'Dirección de Geodesia y Catastro',
      url: 'http://catastro.sanjuan.gob.ar/',
    },
    {
      target: '_blank',
      title: 'Oficina Central de Contrataciones',
      url: 'https://compraspublicas.sanjuan.gob.ar/',
    },
    {
      target: '_blank',
      title:
        'Secretaría de Transformación Digital y Modernización del Estado',
      url: 'https://sgp.sanjuan.gob.ar/',
    },
    {
      target: '_blank',
      title:
        'Dirección de Recursos Humanos y Organización del Empleo Público',
      url: 'https://rrhh.sanjuan.gob.ar/',
    },
    {
      target: '_blank',
      title: 'Instituto de Investigaciones Estadísticas y Económicas',
      url: 'https://web.sanjuan.gob.ar/iiee/',
    },
  ]" />
  <CardGridComponent class="bg-white" titulo="Tesorería General de la Provincia" columnas="5" :data="[
    {
      target: '_self',
      title: 'Descarga de formularios',
      url: '?uid=CUMV-VIEW-TesoreriaGeneralDeLaProvincia',
    },
  ]" />

  <AutoriryComponent :showProtocolo="false" :showLinks="true" :data="[
    {
      title: 'Roberto Gutiérrez',
      subtitle: 'Ministerio de Economía, Finanzas y Hacienda',
      imagen: require('@/assets/img/ministro-hacienda.jpg'),
    },
  ]" />
</template>
